<section class="gw-passport-type">
	<div>
		<gwc-select-passport [traveler]="traveler()"
			[passport]="passport()">
		</gwc-select-passport>
	</div>
	<div class="gw-product-type__section">
		<!-- Select Expediting Options -->
		@if (!getProductDetails(traveler(), passport(), 'product')?.meta?.hide_service || passport().value.product_uuid) {
			<h3 class="gw-chkout-label">Select Expediting Options</h3>
			<div class="gw-product-type__warning">
				Expediting times start once documents are received.                
			</div>
			<mat-radio-group #radio
				aria-label="Select Expediting Options"
				[formControl]="passport()?.controls.option_uuid"
				class="gw-chkout-radio"
				[class.gw-checkout-radio--submitted]="submitted()"
				(change)="serviceUpdated(traveler(), passport())">
				@for (option of getProductDetails(traveler(), passport(), 'services'); track option) {
					<mat-radio-button color="primary"
						class="gw-chkout-radio__item"
						[disabled]="option.sold_out"
						[value]="option.uuid">
							<gw-chkout-passport-option [option]="option">
							</gw-chkout-passport-option>
					</mat-radio-button>
				}
				<div class="gw-chkout__radio-message">
					Please select an expediting option.
				</div>
			</mat-radio-group>
			<gwc-gov-fee [domain]="domain"
				product_type="passport"
				[product_country]="passport().value.country"
				[gov_fee]="getProductDetails(traveler(), passport(), 'gov_fee')"
				[gov_fee_included]="isGovFeeIncluded(traveler(), passport())">
			</gwc-gov-fee>
		}
	</div>
</section>
