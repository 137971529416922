import { Component, input, InputSignal } from '@angular/core'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { VisaOptionComponent } from 'src/app/components/visa.option/visa.option.component'
import { visa_entries } from 'src/app/data/visa.entries'
import { visa_types } from 'src/app/data/visa.types'
import { HelperService } from 'src/app/services/helper.service'
import { ProductService } from 'src/app/services/product.service'
import { ShippingService } from 'src/app/services/shipping.service'
import { TagService } from 'src/app/services/tag.service'
import { TravelerForm, TravelerProductForm } from 'src/app/types/traveler'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'gwc-visa-type',
	standalone: true,
	imports: [
		MatFormFieldModule,
		MatSelectModule,
		ReactiveFormsModule,
		MatRadioModule,
		VisaOptionComponent
	],
	templateUrl: './visa.type.component.html',
	styleUrl: './visa.type.component.scss',
})

export class VisaTypeComponent {
	public traveler: InputSignal<FormGroup<TravelerForm>> = input.required<FormGroup<TravelerForm>>()
	public visa: InputSignal<FormGroup<TravelerProductForm>> = input.required<FormGroup<TravelerProductForm>>()
	public submitted: InputSignal<boolean> = input.required<boolean>()

	protected readonly visa_types = visa_types
	protected readonly entries = visa_entries
	protected readonly domain: string = environment.source.domain

	constructor(
		private productService: ProductService,
		private helperService: HelperService,
		private shippingService: ShippingService,
		private tagService: TagService
	) { }

	public resetVisaProduct(traveler: FormGroup<TravelerForm>, visa: FormGroup<TravelerProductForm>) {
		const entries = this.getProductDetails(traveler, visa, 'entries')
		
		if (entries) {
			visa.controls.product_uuid.patchValue(entries[0]?.uuid)
			visa.controls.option_uuid.reset()
		}
	}

	public getProductDetails(traveler: FormGroup<TravelerForm>, visa: FormGroup<TravelerProductForm>, detail: string) {
		return this.productService.getProductDetails(traveler.value.info as any, visa.value as any, detail)
	}

	public beautifyPeriod(period: string): string {
		return this.helperService.beautifyPeriod(period)
	}

	private checkShipping(traveler): void {
		this.shippingService.checkShipping(traveler.value, false)
	}

	public serviceUpdated(traveler, visa): void {
		this.checkShipping(traveler)
		const passport_value = visa.value
	
		try {
			if (passport_value.product_uuid) {
			this.productService.getGAItem(traveler.value, passport_value)
				.subscribe(response => {
				this.tagService.changeToCart(response, 'add_to_cart')
				})
			}
		} catch (exception) {}
	}
}
