import { Component, input, InputSignal } from '@angular/core'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { DESCRIPTIONS } from 'src/app/data/product.description'
import { Addon } from 'src/app/models/addon.model'
import { ProductService } from 'src/app/services/product.service'
import { ShippingService } from 'src/app/services/shipping.service'
import { TagService } from 'src/app/services/tag.service'
import { TravelerForm, TravelerProductForm } from 'src/app/types/traveler'

@Component({
	selector: 'gwc-select-passport',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		MatSelectModule,
		MatFormFieldModule
	],
	templateUrl: './select.passport.type.component.html',
	styleUrl: './select.passport.type.component.scss',
})

export class SelectPassportTypeComponent {
	public traveler: InputSignal<FormGroup<TravelerForm>> = input.required<FormGroup<TravelerForm>>()
	public passport: InputSignal<FormGroup<TravelerProductForm>> = input.required<FormGroup<TravelerProductForm>>()

	constructor(
		private productService: ProductService,
		private tagService: TagService,
		private shippingService: ShippingService
	) { }

	public passportProductSelected(traveler: FormGroup<TravelerForm>, passport: FormGroup<TravelerProductForm>): void {
		const options = this.getProductDetails(traveler, passport, 'services')
		const passport_value = passport.getRawValue()
		const filtered = options.filter(option => option.uuid === passport_value.option_uuid)
		
		if (filtered.length == 0) {
			passport.controls.option_uuid.reset()
		} else {
			this.checkShipping(traveler)
		}

		if (options.length === 1) {
			passport.controls.option_uuid.patchValue(options[0].uuid)
		}

		try {
			if (passport_value.product_uuid && passport_value.option_uuid) {
				this.productService.getGAItem(traveler.value, passport_value)
					.subscribe(response => {
						this.tagService.changeToCart(response, 'add_to_cart')
					})
			}
		} catch (exception) {}
	}
	
	public getProductDetails(traveler: FormGroup<TravelerForm>, passport: FormGroup<TravelerProductForm>, detail: string, filter = false) {
		let productDetails = this.productService.getProductDetails(traveler.value.info as any, passport.value as any, detail)

		return productDetails
	}

	private checkShipping(traveler): void {
		this.shippingService.checkShipping(traveler.value, false)
	}

	public getProductDescription(traveler: FormGroup<TravelerForm>, passport: FormGroup<TravelerProductForm>): string {
		const { type } = passport.getRawValue()

		if (type in DESCRIPTIONS) {
			const subtype = this.getProductDetails(traveler, passport, 'subtype')
			return DESCRIPTIONS[type][subtype]
		}

		return ''
	}
}
