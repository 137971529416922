<section class="gw-chkout-visa">
	<div class="gw-product-type__section">
		<span class="gw-chkout-visa__label">
			Select the type of visa you need.
		</span>
		<mat-form-field class="gw-chkout-field gw-chkout-field--full gw-chkout-field--border">
			<mat-label>Visa Type</mat-label>
			<mat-select [formControl]="visa()?.controls.subtype"
				(selectionChange)="resetVisaProduct(traveler(), visa())">
				@for (type of getProductDetails(traveler(), visa(), 'types'); track type) {
					<mat-option [value]="type">
						{{ visa_types[type] }}
					</mat-option>
				}
			</mat-select>
		</mat-form-field>
		<span class="gw-chkout-visa__label">
			Select the entry type.
		</span>
		<mat-form-field class="gw-chkout-field gw-chkout-field--full gw-chkout-field--border">
			<mat-label>Entry Type</mat-label>
			<mat-select [formControl]="visa()?.controls.product_uuid">
				@for (entry of getProductDetails(traveler(), visa(), 'entries'); track entry.uuid) {
					<mat-option [value]="entry.uuid">
						{{ entries[entry.entry_type] }}/{{ beautifyPeriod(entry.max_validity) }} Validity/{{ beautifyPeriod(entry.max_stay) }} Stay
					</mat-option>
				}
			</mat-select>
			<mat-error>Please select an entry type</mat-error>
		</mat-form-field>
	</div>
	@if (!getProductDetails(traveler(), visa(), 'product')?.meta?.hide_service && visa()?.controls.product_uuid.value && visa()?.controls.subtype.value) {
		<div class="gw-product-type__section">
			<h3 class="gw-chkout-label">Select Service Option</h3>
			<mat-radio-group #radio
				aria-label="Select Expediting Options"
				[formControl]="visa()?.controls.option_uuid"
				class="gw-chkout-radio"
				[class.gw-checkout-radio--submitted]="submitted()"
				(change)="serviceUpdated(traveler(), visa())">
				@for (option of getProductDetails(traveler(), visa(), 'services'); track option; let idx = $index) {
				<mat-radio-button color="primary"
					class="gw-chkout-radio__item gw-chkout-radio__item--center"
					[disabled]="option.sold_out"
					[value]="option.uuid">
					<gw-chkout-visa-option [service]="option"
						[idx]="idx">
					</gw-chkout-visa-option>
				</mat-radio-button>
				}
				<div class="gw-chkout__radio-message">
					Please select an expediting option.
				</div>
			</mat-radio-group>
		</div>
	}
</section>