export const environment: any = {
  "production": false,
  "API": "https://api.dev.govworks.com/api/",
  "visas": true,
  "header" : {
    "logo": "https://govworks-email-assets.s3.amazonaws.com/rmp_ca.svg"
  },
  "support": {
    "phone": "8334367065"
  },
  "login": {
    "enabled": false,
    "logo":"assets/svg/rmp_logo.svg"
  },
  "fedex_logo": false,
  "legal_links": {
    "terms": "https://rushmypassport.ca/fedex-terms-and-conditions/",
    "privacy": "https://rushmypassport.ca/fedex-privacy-policy/",
    "refund": "https://rushmypassport.ca/fedex-refund-policy/"
  },
  "main_product": {
    "citizenship": "CA",
    "residence_country": "CA",
    "country": "CA",
    "type": "ca_passport"
  },
  "source": {
    "domain": "rmp_ca",
    "main_website": "https://rushmypassport.ca",
    "promo_code": true,
    "name": "FedEx",
    "countries": ["CA", "US"],
    "currency": "CAD"
  },
  "footer": {
    "logo": "https://govworks-email-assets.s3.amazonaws.com/rmp_ca.svg",
    "logo_link": "https://rushmypassport.ca",
    "copyright": "RushMyPassport.ca, LLC"
  },
  "create_lead": true, 
  "seals": {
    "mcafee": "",
    "truste": "",
    "norton": ""
  },
  "summary_cta_icon": false,
  "client_url": "https://my.dev.govworks.com",  
  "landing_pages":  {
    "root_type": "passport",
    "passport": {
      "enabled": false
    },
    "visa": {
      "enabled": false
    }
  }, 
  "locations_page": {
    "enabled": false
  },
  "tags": {}
};