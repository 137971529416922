import { Component, input, InputSignal } from '@angular/core'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatRadioModule } from '@angular/material/radio'
import { IDPOptionComponent } from 'src/app/components/idp.option/idp.option.component'
import { ProductService } from 'src/app/services/product.service'
import { ShippingService } from 'src/app/services/shipping.service'
import { TagService } from 'src/app/services/tag.service'
import { TravelerForm, TravelerProductForm } from 'src/app/types/traveler'

@Component({
	selector: 'gwc-idp-type',
	standalone: true,
	imports: [
		MatFormFieldModule,
		ReactiveFormsModule,
		MatRadioModule,
		IDPOptionComponent
	],
	templateUrl: './idp.type.component.html',
	styleUrl: './idp.type.component.scss',
})

export class IdpTypeComponent {
	public traveler: InputSignal<FormGroup<TravelerForm>> = input.required<FormGroup<TravelerForm>>()
	public product: InputSignal<FormGroup<TravelerProductForm>> = input.required<FormGroup<TravelerProductForm>>()
	public submitted: InputSignal<boolean> = input.required<boolean>()

	constructor(
		private productService: ProductService,
		private shippingService: ShippingService,
		private tagService: TagService
	) { }

	public getProductDetails(traveler: FormGroup<TravelerForm>, product: FormGroup<TravelerProductForm>, detail: string) {
		return this.productService.getProductDetails(traveler.value.info as any, product.value as any, detail)
	}

	private checkShipping(traveler): void {
		this.shippingService.checkShipping(traveler.value, false)
	}

	public serviceUpdated(traveler, visa): void {
		this.checkShipping(traveler)
		const passport_value = visa.value
	
		try {
		  if (passport_value.product_uuid) {
			this.productService.getGAItem(traveler.value, passport_value)
			  .subscribe(response => {
				this.tagService.changeToCart(response, 'add_to_cart')
			  })
		  }
		} catch (exception) {}
	}
}
