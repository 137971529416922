<div class="gw-product-type__section">
	<h3 class="gw-chkout-label">
		Select the type of passport you need.
	</h3>
	<div class="gw-chkout-form">
		<mat-form-field class="gw-chkout-field
			gw-chkout-field--border"
			[hideRequiredMarker]="true">
			<mat-label>Passport Type</mat-label>
			<mat-select [formControl]="passport()?.controls.product_uuid"
				(selectionChange)="passportProductSelected(traveler(), passport())"
				[hideSingleSelectionIndicator]="true">
				@for (passportDetails of getProductDetails(traveler(), passport(), 'products'); track passportDetails.uuid) {
					<mat-option [value]="passportDetails.uuid">
						{{ passportDetails.label }}
					</mat-option>
				}
			</mat-select>
			<mat-error>Please select a passport type.</mat-error>
		</mat-form-field>
	</div>
	@if (passport().value.product_uuid) {
		<span class="gw-product-type__description"
			[innerHtml]="getProductDescription(traveler(), passport())">
		</span>
	}
</div>
