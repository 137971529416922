import { Component, InputSignal, input } from '@angular/core'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { PassportOptionComponent } from 'src/app/components/passport.option/passport.option.component'
import { OrderService } from 'src/app/services/order.service'
import { ProductService } from 'src/app/services/product.service'
import { ShippingService } from 'src/app/services/shipping.service'
import { TagService } from 'src/app/services/tag.service'
import { TravelerForm, TravelerProductForm } from 'src/app/types/traveler'
import { environment } from 'src/environments/environment'
import { GovFeeComponent } from '../gov.fee/gov.fee.component'
import { Addon } from 'src/app/models/addon.model'
import { SelectPassportTypeComponent } from '../shared/select.passport.type/select.passport.type.component'

@Component({
	selector: 'gwc-passport-type',
	standalone: true,
	templateUrl: './passport.type.component.html',
	styleUrl: './passport.type.component.scss',
	imports: [
		MatFormFieldModule,
		MatSelectModule,
		ReactiveFormsModule,
		MatRadioModule,
		PassportOptionComponent,
		GovFeeComponent,
		SelectPassportTypeComponent
	]
})

export class PassportTypeComponent {
	public traveler: InputSignal<FormGroup<TravelerForm>> = input.required<FormGroup<TravelerForm>>()
	public passport: InputSignal<FormGroup<TravelerProductForm>> = input.required<FormGroup<TravelerProductForm>>()
	public submitted: InputSignal<boolean> = input.required<boolean>()

	protected readonly domain: string = environment.source.domain

	constructor(
		private productService: ProductService,
		private tagService: TagService,
		private shippingService: ShippingService,
		private orderService: OrderService
	) { }
	
	public getProductDetails(traveler: FormGroup<TravelerForm>, passport: FormGroup<TravelerProductForm>, detail: string, filter = false) {
		let productDetails = this.productService.getProductDetails(traveler.value.info as any, passport.value as any, detail)

		return productDetails
	}

	private checkShipping(traveler): void {
		this.shippingService.checkShipping(traveler.value, false)
	}

	public serviceUpdated(traveler, passport): void {
		this.checkShipping(traveler)
	
		const passport_value = passport.value
		if (passport_value && passport_value.initial) {
			this.orderService.preCheckInsurance(passport)
		}
	
		try {
			if (passport_value.product_uuid) {
			this.productService.getGAItem(traveler.value, passport_value)
				.subscribe(response => this.tagService.changeToCart(response, 'add_to_cart'))
			}
		} catch (exception) {}
	}

	public isGovFeeIncluded(traveler: FormGroup<TravelerForm>, passport: FormGroup<TravelerProductForm>): boolean {
		const service_included = this.productService.getProductDetails(traveler.getRawValue().info, passport.getRawValue(), 'gov_fee_included')

		if (service_included) {
			return true
		} 

		const addons = this.getProductDetails(traveler, passport, 'addons')
		
		if (addons) {
			const addons_array = passport.controls.addons.getRawValue()

			return addons_array.some((addon) => {
				const addon_object = addons.find((item) => item.uuid == addon)
	
				if (addon_object) {
					return addon_object.slug === 'government_fee'
				}
	
				return false
			})
		}

		return false
	}
}
