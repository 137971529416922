<section class="gw-ca-passport-type">
	<div class="gw-product-type__section">
		<h3 class="gw-chkout-label">
			Where are you right now?
		</h3>
		<p class="gw-product-type__description">It is important for us to know where you are located as this will impact the shipping options made available to you as well as the Canadian passport services available.</p>
		<mat-radio-group aria-label="Select Expediting Options"
			[formControl]="passport()?.controls.applying_from"
			class="gw-chkout-radio"
			[class.gw-checkout-radio--submitted]="submitted()"
			(change)="onApplyingFromChange()">
			<mat-radio-button color="primary"
				class="gw-chkout-radio__item"
				value="CA">
				<span class="fi fi-ca gw-ca-passport-type__flag"></span>
				 <strong class="gwc-ca-passport-type__location">Canada</strong>
			</mat-radio-button>
			<mat-radio-button color="primary"
				class="gw-chkout-radio__item"
				value="US">
				<span class="fi fi-us gw-ca-passport-type__flag"></span>
				 <strong class="gwc-ca-passport-type__location">United States</strong>
			</mat-radio-button>
		</mat-radio-group>
	</div>
	<div>
		<gwc-select-passport [traveler]="traveler()"
			[passport]="passport()">
		</gwc-select-passport>
		
	</div>
	<div class="gw-product-type__section">
		@if (!getProductDetails(traveler(), passport(), 'product')?.meta?.hide_service || passport().value.product_uuid) {
			<h3 class="gw-chkout-label">Select Service Option</h3>
			<mat-radio-group #radio
				aria-label="Select Expediting Options"
				[formControl]="passport()?.controls.option_uuid"
				class="gw-chkout-radio"
				[class.gw-checkout-radio--submitted]="submitted()"
				(change)="serviceUpdated(traveler(), passport())">
				@for (option of getProductDetails(traveler(), passport(), 'services'); track option) {
					<mat-radio-button color="primary"
						class="gw-chkout-radio__item"
						[disabled]="option.sold_out"
						[value]="option.uuid">
						<gw-chkout-passport-option [option]="option">
						</gw-chkout-passport-option>
					</mat-radio-button>
				}
				<div class="gw-chkout__radio-message">
					Please select an expediting option.
				</div>
			</mat-radio-group>
			<gwc-gov-fee [domain]="domain"
				[product_type]="passport().value.type"
				[product_country]="passport().value.country"
				[gov_fee]="getProductDetails(traveler(), passport(), 'gov_fee')"
				[gov_fee_included]="false">
			</gwc-gov-fee>

		}
		@if (passport()?.controls.applying_from.value) {
			<div class="gw-ca-expediting">
				<h3 class="gw-chkout-label">
					When do you need the passport?
				</h3>
				<mat-radio-group #radio
					aria-label="When do you need the passport?"
					[formControl]="passport().controls.processing_type"
					class="gw-chkout-radio"
					[class.gw-checkout-radio--submitted]="submitted()">
					@if (passport()?.controls.applying_from.value !== 'US') {
						<mat-radio-button color="primary"
							class="gw-chkout-radio__item"
							value="expedited">
							<span class="gw-ca-expediting__option">
								<strong>1-9 Business Days</strong>
								<span>
									Must submit application in-person
								</span>
							</span>
						</mat-radio-button>
					}
					<mat-radio-button color="primary"
						class="gw-chkout-radio__item"
						value="regular">
						<span class="gw-ca-expediting__option">
							<strong>10-20 Business Days</strong> 
							<span>
								Can mail application in for processing
							</span>
						</span>
					</mat-radio-button>
					<div class="gw-chkout__radio-message">
						Please select an expediting option.
					</div>
				</mat-radio-group>
			</div>
		}
	</div>
</section>